<template>
  <b-sidebar
    id="add-new-room-sidebar"
    :visible="isEditRoomSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    @change="(val) => $emit('update:is-edit-room-sidebar-active', val)"
    no-header
    right
  >
    <template #default="{ hide }">
      <form-wizard
        ref="transferWizardIndex"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Kaydet"
        back-button-text="Geri"
        class="mb-3"
      >
        <tab-content :title="$t('Room Edit')">
            <div>
                <b-form-group
                :label="$t('Room Name')"
                label-for="room-name"
                >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HomeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-first-name"
                    type="text"
                    @blur="$v.room_data.name.$touch()"
                    :placeholder="room_name"
                    v-model="room_data.name"
                  />
                  <small
                    v-if="isSubmit && !$v.room_data.name.required"
                    class="form-text text-danger"
                    >{{ $t("This field is required") }}</small
                  >
                  </b-input-group>
              </b-form-group>
            </div>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-right">
            <b-button
              v-if="props.activeTabIndex === 0"
              @click="validationForm()"
              size="md"
              variant="primary"
            >
              {{ $t("Save") }}
            </b-button>
          </div>
          <div class="wizard-footer-right">
            <b-button
              v-if="props.activeTabIndex === 0"
              size="md"
              class="mr-1"
              variant="primary"
              @click="hide"
            >
              {{ $t("Back") }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BRow,
  BCol,
  BVToastPlugin,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BButton,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {mapActions,mapGetters} from 'vuex';

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";

import * as rules from 'vee-validate/dist/rules'
import { extend } from 'vee-validate';
import { required } from "vuelidate/lib/validators";
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BCardText,
    BVToastPlugin,
    BFormDatepicker,
    FormWizard,
    TabContent,
    BFormGroup,
    BTabs,
    ToastificationContent,
    BTab,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    // Form Validation
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditRoomSidebarActive",
    event: "update:is-edit-room-sidebar-active",
  },
  props: {
    isEditRoomSidebarActive: {
      type: Boolean,
      required: true,
    },
    room_id: {
      required: true
    },
    room_name: {
      required: true,
    },
  },
  data() {
    return {
      studio_id: '',
      required,
      personData: {},
      res_type: {},
      emailValue: "",
      min_date: "",
      max_date: "",
      start_value: "",
      end_value: "",
      formatted: "",
      selected: "",
      room_data:{
        name:""
      },
      isSubmit: false
    };
  },
  computed: {
    filtered_studio_data(){
      let data = []
      this.room_data.map(x=> {
        if(x.name.toUpperCase().includes(this.searchQuery.toUpperCase())){
          data.push(x)
        }
      })
      let _data = data.slice(((this.currentPage-1)*this.perPage),(((this.currentPage-1)*this.perPage)+this.perPage))
       return _data.length === 0 ? data : _data
    },
  },
  watch:{
    isEditRoomSidebarActive(val){
      if(val === true){
         this.room_data.name = this.room_name
      }
    }
  },
  methods: {
    update_room() {
      this.$database.RoomService.update_room(
        this.room_id,
        this.studio_id,
        this.room_data.name,
        true
      ).then((res) => {
        console.log("res: " + res);
        if (!res.is_success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Action failed!'),
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Room Edited Succesful'),
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$emit('update:is-edit-room-sidebar-active', false)
        }
      });
    },
    validationForm() {
      if (this.$refs.transferWizardIndex.activeTabIndex === 0) {
      //  return new Promise((resolve, reject) => {
      //     this.$refs.accountRules.validate().then((success) => {
      //       if (success) {
      //         this.update_room();
      //         resolve(true);
      //       } else {
      //         reject();
      //       }
      //     });
      //    });
      if(this.$v.room_data.name.required){
        this.update_room();
      }else{
        this.isSubmit = true;
      }
     }
    },
  },
  validations:{
    room_data: {
      name: {
        required
      }
    }
  },
  created() {
    this.studio_id = this.$store.getters["auth/userInfo"].company_branch_id
    // this.studio_id = this.$route.params.studioID;
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" scoped src="@/styles/scss/studioRoomEditScoped.scss"></style>
