<template>
  <div>
    <b-card no-body class="mb-0">
      <room-add-new
      :is-add-new-room-sidebar-active.sync="isAddNewRoomSidebarActive"
       ref="addNewRoomSidebar"
      />
      <room-edit
      :is-edit-room-sidebar-active.sync="isEditRoomSidebarActive"
      :room_name="edit_room_name"
      :room_id="edit_room_id" />
      <room-capacity
      :is-capacity-room-sidebar-active.sync="isCapacityRoomSidebarActive"
      :room_capacity="edit_capacity"
      :room_id="edit_room_id"/>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
              <b-button variant="primary"
              @click="showRoomSidebar"
               >

                <span class="text-nowrap">{{ $t("Add Room") }}</span>
              </b-button>
            </div>
          </b-col>
          <div>
      </div>
        </b-row>
      </div>
      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="filtered_studio_room_data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :empty-filtered-text="$t('There are no records matching your request!')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(actions)="data">
          <template v-if="isMobile===true">
                <b-dropdown  variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                  </template>

                  <b-dropdown-item @click="edit_tab_active(data.item.room_name,data.item.room_id)" >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{$t('Edit')}}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                     @click="success(data.item.room_id,data.item.is_active)">
                    <feather-icon icon="ActivityIcon" />
                    <span class="align-middle ml-50">
                      {{ data.item.is_active === false ? $t("Activate")  : $t("Deactivate") }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
          </template>

          <template v-if="isMobile===false">
          <b-button
            size="sm"
            class="mr-1"
            variant="primary"
            @click="edit_tab_active(data.item.room_name,data.item.room_id)"
            >{{ $t("Edit") }}</b-button
          >
          <b-button size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            :variant=" data.item.is_active === false ? 'success' :'danger'"
            @click="success(data.item.room_id,data.item.is_active)">
            {{ data.item.is_active === false ? $t("Activate")  : $t("Deactivate") }}
          </b-button>
        <!-- <b-button size="sm" class="btn-size ml-1" variant="info" :to="{
          name: 'StudioDoorCreate', params: { companyBranchId:companyBranchId, roomId:data.item.room_id },}">
        Kapı Tanımla
      </b-button> -->
      </template>

      </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6"
            sm=""
            class=" d-flex align-items-left justify-content-right justify-content-sm-start radius 0">
          <b-form-select v-model="selected" :options="options" @change="changeFiltering" class="select-config">
          </b-form-select>
          </b-col>
          <small v-if="$i18n.locale === 'tr'" class="mt-1">içerisindeki {{total_items}} kayıttan {{currentPage*1}}-{{currentPage*perPage}} arası ({{currentPage}}.sayfa) gösteriliyor </small>
          <small v-if="$i18n.locale === 'en'" class="mt-1">showing {{currentPage*1}}-{{currentPage*perPage}} (page {{currentPage}}) of {{total_items}} records</small>
          <!-- Pagination -->
          <b-col cols="6" sm="" class=" d-flex align-items-right justify-content-right justify-content-sm-end radius 0">
            <b-pagination
              v-model="currentPage"
              :total-rows="total_items"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BFormRadio,
  BFormRadioGroup,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import RoomAddNew from "./studio_add_new_room.vue";
import RoomEdit from "./studio_room_edit.vue";
import RoomCapacity from "./studio_room_capacity.vue";
import {mapActions,mapGetters} from 'vuex';
import Ripple from 'vue-ripple-directive'
import DataBase from "@/services/DataBase.js";
export default {
  components: {
    BFormSelect,
    BFormRadio,
    BFormRadioGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    RoomAddNew,
    RoomEdit,
    RoomCapacity,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
  },
directives: {
    Ripple,
  },
  data() {
    return {

      edit_room_id:'',
      edit_capacity: '',
      edit_room_name:'',
      currentPage: null,
      total_items:null,
      isSortDirDesc: true,
      sortBy: "id",
      searchQuery: "",
      perPage: 25,
      locale: this.$i18n.locale,
      perPageOptions: [10, 25, 50, 100],
      isAddNewRoomSidebarActive: false,
      isEditRoomSidebarActive: false,
      isCapacityRoomSidebarActive: false,
      tableColumns: [
        { key: 'room_id',thClass: 'd-none',tdClass: 'd-none'},
        { key: "room_name", label: this.$t("Room Name"), sortable: true },
        { key: "actions", label: this.$t("TRANSACTIONS") },
      ],
      studioRoomData:[],
      selected: 'tüm',
      options: [
          { text: this.$t('All Rooms'), value: 'tüm' },
          { text: this.$t('Active Rooms'), value: 'aktif' },
          { text: this.$t('Deactive Rooms'), value: 'deaktif' },
        ],
        companyBranchId: ''

    };
  },
  computed: {
      filtered_studio_room_data()
      {
      let data = [];
      this.studioRoomData.map((x) => {
        if (x.room_name.toUpperCase().includes(this.searchQuery.toUpperCase())) {
          data.push(x);
          this.total_items=data.length;
          this.currentPage=1
        }
      });
      return data
    }
  },
  watch:{
     isAddNewRoomSidebarActive(val){
        if(val===false)
        {
            this.get_room_data();
        }
      },
    studioRoomData(val){
      this.totalRows = val.length
    },
    //sidebar olarak çıkan edit ile işimiz bittikten sonra salon verilerini tekrar getirir
    isEditRoomSidebarActive(){
      this.get_room_data()
    },
    isCapacityRoomSidebarActive(){
      this.get_room_data()
    }
  },
  methods: {
    //salonların aktiflik, deaktiflik durumlarına göre filtrelenebilmesi
    changeFiltering(arg) {
        if (arg === 'tüm') {
          this.studioRoomData = this.studioBaseRoomData;
        } else if (arg === 'aktif') {
          //aktif seçilirse base veriler içinde map et aktif true olanları tablo verisi olarak al
          let data = [];
          this.studioBaseRoomData.map((x) => {
            if (x.is_active === true) {
              data.push(x);
              this.total_items= data.length;
              this.currentPage = 1
            }
          });
          this.studioRoomData = data;
        } else if (arg === 'deaktif') {
          //deaktif seçilirse base veriler içinde map et aktif false olanları tablo verisi olarak al
          let data = [];
          this.studioBaseRoomData.map((x) => {
            if (x.is_active === false) {
              data.push(x);
              this.total_items = data.length;
              this.currentPage = 1
            }
          });
          this.studioRoomData = data;
      } else {
        //null gelebilir..
        this.studioRoomData = this.studioBaseRoomData;
      }
    },
    edit_tab_active(name,id){
      this.edit_room_name = name
      this.edit_room_id = id
      this.isEditRoomSidebarActive = true
    },
    capacity_tab_active(capacity,id){
      this.edit_capacity = capacity
      this.edit_room_id = id
      this.isCapacityRoomSidebarActive = true
    },
    get_room_data()
    {
      DataBase.RoomService.get_studio_room_list(this.companyBranchId).then(
        (res) => {
          if (!res.is_success)
          return;
          this.studioBaseRoomData = res.result;
          this.studioRoomData= res.result;
          this.total_items=res.result.length;
          this.selected = 'tüm'
        }
      );
    },
    showRoomSidebar()
    {
      //sidebar sayfasında açıyoruz
        this.isAddNewRoomSidebarActive = true
        //sidebar sayfasında  firstTabDisplay metodunu çalıştırıyoru
        this.$refs.addNewRoomSidebar.sideBarOpen(this.companyBranchId)
    },

    success(val,is_active){
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t('Are You Sure You Want To Change The Room Activity Status?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
      if(is_active === true ){
        this.$database.StudioService.studio_room_deactive(val,false).then(res => {
          this.get_room_data();
          if (!res.is_success) {
          this.$functions.Messages.error('Action failed!');
          }
          else {
          this.$functions.Messages.success('Room status changed');
          }
          })
      } else {
        this.$database.StudioService.studio_room_deactive(val,true).then(res => {
          this.get_room_data();
          if (!res.is_success) {
          this.$functions.Messages.error('Action failed!');
          }
          else {
          this.$functions.Messages.success('Room status changed');
          }

        })
      }
        }
      })
    },
  },
  mounted() {
      this.companyBranchId = this.$store.getters["auth/userInfo"].company_branch_id
      // this.studio_id = this.$route.params.studioID
      this.locale = "tr";
      this.get_room_data();
  },
  }
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/studioRoomList.scss" scoped></style>
