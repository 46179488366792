<template>
  <b-sidebar
      id="add-new-room-sidebar"
      :visible="isCapacityRoomSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      @change="(val) => $emit('update:is-capacity-room-sidebar-active', val)"
      no-header
      right
  >
    <template #default="{ hide }">
      <form-wizard
          ref="transferWizardIndex"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Kaydet"
          back-button-text="Geri"
          class="mb-3"
      >
        <tab-content :title="$t('Room Capacity Edit')">
          <div>
            <b-form-group
                :label="$t('Capacity')"
                label-for="room-capacity"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="HomeIcon"/>
                </b-input-group-prepend>
                <b-form-input
                    id="capacity"
                    type="number"
                    @blur="$v.room_data.capacity.$touch()"
                    aria-placeholder="0"
                    v-model="room_data.capacity"
                />
                <small
                    v-if="isSubmit && !$v.room_data.capacity.required"
                    class="form-text text-danger"
                >{{ $t("This field is required") }}</small
                >
              </b-input-group>
            </b-form-group>
          </div>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-right">
            <b-button
                v-if="props.activeTabIndex === 0"
                @click="validationForm()"
                size="md"
                variant="primary"
            >
              {{ $t("Save") }}
            </b-button>
          </div>
          <div class="wizard-footer-right">
            <b-button
                v-if="props.activeTabIndex === 0"
                size="md"
                class="mr-1"
                variant="primary"
                @click="hide"
            >
              {{ $t("Back") }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BSidebar,
  BTab,
  BTabs,
  BVToastPlugin,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import * as rules from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { required } from 'vuelidate/lib/validators'

Object.keys(rules)
    .forEach(rule => {
      extend(rule, rules[rule])
    })

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BCardText,
    BVToastPlugin,
    BFormDatepicker,
    FormWizard,
    TabContent,
    BFormGroup,
    BTabs,
    ToastificationContent,
    BTab,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    // Form Validation
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isCapacityRoomSidebarActive",
    event: "update:is-capacity-room-sidebar-active",
  },
  props: {
    isCapacityRoomSidebarActive: {
      type: Boolean,
      required: true,
    },
    room_id: {
      required: true
    },
    room_capacity: {
      required: true,
    },
  },
  data() {
    return {
      studio_id: '',
      required,
      personData: {},
      res_type: {},
      emailValue: "",
      min_date: "",
      max_date: "",
      start_value: "",
      end_value: "",
      formatted: "",
      selected: "",
      room_data: {
        capacity: 0
      },
      isSubmit: false
    };
  },
  computed: {
    filtered_studio_data() {
      let data = []
      this.room_data.map(x => {
        if (x.name.toUpperCase().includes(this.searchQuery.toUpperCase())) {
          data.push(x)
        }
      })
      let _data = data.slice(((this.currentPage - 1) * this.perPage), (((this.currentPage - 1) * this.perPage) + this.perPage))
      return _data.length === 0 ? data : _data
    },
  },
  watch: {
    isCapacityRoomSidebarActive(val) {
      if (val === true) {
        this.room_data.capacity = this.room_capacity
      }
    }
  },
  methods: {
    update_room() {
      this.$database.RoomService.update_capacity(
          this.room_id,
          this.studio_id,
          this.room_data.capacity
      ).then((res) => {
        console.log(res);
        if (!res.is_success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Action failed!'),
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Room Capacity Successfully Edited'),
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$emit('update:is-capacity-room-sidebar-active', false)
        }
      });
    },
    validationForm() {
      if (this.$refs.transferWizardIndex.activeTabIndex === 0) {
        //  return new Promise((resolve, reject) => {
        //     this.$refs.accountRules.validate().then((success) => {
        //       if (success) {
        //         this.update_room();
        //         resolve(true);
        //       } else {
        //         reject();
        //       }
        //     });
        //    });
        if (this.$v.room_data.capacity.required) {
          this.update_room();
        } else {
          this.isSubmit = true;
        }
      }
    },
  },
  validations: {
    room_data: {
      capacity: {
        required
      }
    }
  },
  created() {
    this.studio_id = this.$store.getters["auth/userInfo"].company_branch_id
    // this.studio_id = this.$route.params.studioID;
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" scoped src="@/styles/scss/studioRoomCapacity.scss"></style>
