<template>
  <b-sidebar
    id="add-new-room-sidebar"
    :visible="isAddNewRoomSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    @change="(val) => $emit('update:is-add-new-room-sidebar-active', val)"
    no-header
    right
  >
    <template>
      <form-wizard
        ref="transferWizardIndex"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Kaydet"
        back-button-text="Geri"
        class="mb-3"
      >
        <tab-content :title="$t('Add Room')">
          <validation-observer ref="accountRules" tag="form">
            <div>
              <validation-provider
                #default="{ errors }"
                name="Room Name"
                rules="required|max:500"
              >
                <b-form-group
                :label="$t('Room Name')"
                label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="BriefcaseIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-first-name"
                    type="text"
                    :placeholder="$t('Room Name')"
                    v-model="room_data.name"
                  />
                  </b-input-group>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </b-form-group>
              </validation-provider>
            </div>
          </validation-observer>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-right">
            <b-button
              v-if="props.activeTabIndex === 0"
              @click="validationForm()"
              size="md"
              variant="primary"
            >
              {{ $t("Save") }}
            </b-button>
          </div>
          <div class="wizard-footer-right">
            <b-button
              size="md"
              class="mr-1"
              variant="primary"
              @click="()=>(isActive=false)"
            >
              {{ $t("Back") }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BRow,
  BCol,
  BVToastPlugin,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BButton,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as rules from 'vee-validate/dist/rules'
import { extend } from 'vee-validate';
import { required } from "@validations";


export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BCardText,
    BVToastPlugin,
    BFormDatepicker,
    FormWizard,
    TabContent,
    BFormGroup,
    BTabs,
    ToastificationContent,
    BTab,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  // model: {
  //   prop: "isAddNewRoomSidebarActive",
  //   event: "update:is-add-new-room-sidebar-active",
  // },
  props: {
    isAddNewRoomSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      personData: {},
      res_type: {},
      studio_id: "38057796-8c52-4e0e-a571-9bf71a355b2a",
      min_date: "",
      max_date: "",
      start_value: "",
      end_value: "",
      formatted: "",
      selected: "",
      room_data:{
        name:""
      }
    };
  },
  methods: {
   // ...mapActions('studioStore',['GET_ROOM_DATA']),
    get_max_date() {
      this.$database.DistributorService.get_licence_date(this.studio_id).then(
        (res) => {
          if (!res.is_success) return;
          this.min_date = res.result.max;
        }
      );
    },
    create_room()
    {
      this.$database.RoomService.room_create(
        this.$store.getters["auth/userInfo"].company_branch_id,
        this.room_data.name,
        true
      ).then((res) => {
        if (!res.is_success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Action failed!"),
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Room Registration Successful"),
              icon: "EditIcon",
              variant: "success",
            },

          });
          this.isActive=false
        }
      });
    },
    validationForm() {
      if (this.$refs.transferWizardIndex.activeTabIndex === 0) {
       return new Promise((resolve, reject) => {
          this.$refs.accountRules.validate().then((success) => {
            if (success) {
              this.create_room();
              resolve(true);
            } else {
              reject();
            }
          });
         });
     }
    },
      sideBarOpen(studio_id){
      //Sayfa açılır iken sayfadaki verileri sıfırlıyoruz
        this.room_data.name=null;
    },
  },
  created() {
    // this.studio_id = this.$route.params.studioID;
  },
  computed:{
     isActive: {
          get(){
              return this.isAddNewRoomSidebarActive
          },
          set(d){
              this.$emit('update:is-add-new-room-sidebar-active',d)
          }
      }
  }
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/studioAddNewRoom.scss"></style>
